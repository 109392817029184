import { ref, reactive } from "vue";

const waitingPackageData = ref([])
const scannedItem = ref([])
const selectedItem = ref({})
const state = reactive({
  reprintID: "",
  reprintTrackingCode:"",
  searchText: "",
  searchedColumn: "",
  selectedRowKeys: [],
  // Check here to configure the default column
  loading: false,
  modalShowPrintLabel : false,
  modalShowRMADetail : false,
  currentShowRMA : '',
  searchInput : '',
  modalShowPreparePackage : false,
  labelUrl:"",
  packageUrl:"",
  showPrintAction: false,
  onRmaEditMode:false
});

export default function dataState() { 
  const setSelectedItem = (data) => { 
    // console.log(data, "data")
    state.modalShowRMADetail = true
    // console.log(state.modalShowRMADetail, "data state.modalShowRMADetail")
    selectedItem.value = data
    // console.log(selectedItem.value, "data selectedItem.value")
  }
  return {
    setSelectedItem,
    selectedItem,
    scannedItem,
    waitingPackageData, 
    state
  };
}
